import { createRouter, createWebHistory } from 'vue-router';
import FormAdd from '@/components/FormAdd.vue';
import SubmitForm from '@/components/SubmitForm.vue';
import ViewResponses from '@/components/ViewResponses.vue';
import FormAnalytics from '@/components/FormAnalytics.vue';
import AdminLogin from '@/components/AdminLogin.vue'; 

const routes = [
  { path: '/login', component: AdminLogin },
  {
    path: '/create',
    component: FormAdd,
    meta: { requiresAuth: true }
  },
  {
    path: '/responses/:formId',
    component: ViewResponses,
    meta: { requiresAuth: true }
  },
    {
    path: '/edit/:formId', // Add this route
    component: FormAdd, // Use FormAdd.vue for editing if applicable
    meta: { requiresAuth: true },
  },
  {
    path: '/submit/:formId',
    component: SubmitForm
  },
  {
    path: '/analytics/:formId',
    component: FormAnalytics, // New route for analytics
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('adminLoggedIn') === 'true';
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
