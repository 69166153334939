import { createApp } from 'vue'; // For Vue 3
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import router from './router';
import i18n from './i18n'; // Make sure this path points to src/i18n.js

const app = createApp(App);  // Vue 3 uses createApp()
app.use(router);  		    // Apply the router
app.use(i18n); 			   // Use i18n
app.mount('#app');        // Mount the Vue app
