<template>
  <div class="container mt-5">
    <h2>Form Analytics</h2>
    
    <p v-if="loading">Loading analytics...</p>

    <div v-if="!loading">
      <h4>Response Count: {{ analytics.response_count }}</h4>

      <h4>Most Selected Choices</h4>
      <div v-for="(choices, label) in analytics.choices_count" :key="label">
        <p><strong>{{ label }}</strong></p>
        <ul>
          <li v-for="(count, option) in choices" :key="option">{{ option }}: {{ count }} times</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      analytics: {
        response_count: 0,
        choices_count: {}
      },
      loading: true
    };
  },
  methods: {
    fetchAnalytics() {
      const formId = this.$route.params.formId;
      fetch(`https://dasroor.com/api/get_form_analytics.php?form_id=${formId}`)
        .then(response => response.json())
        .then(data => {
          this.analytics = data;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching analytics:', error);
          this.loading = false;
        });
    }
  },
  mounted() {
    this.fetchAnalytics();
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin-top: 20px;
}
</style>
