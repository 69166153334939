<template>
  <div class="container mt-5" :dir="isRtl ? 'rtl' : 'ltr'">
    <!-- Language Selector -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light mb-4">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" @click="changeLanguage('en')" :class="{ active: selectedLanguage === 'en' }">
          <a class="nav-link" href="#">English</a>
        </li>
        <li class="nav-item" @click="changeLanguage('fa')" :class="{ active: selectedLanguage === 'fa' }">
          <a class="nav-link" href="#">فارسی</a>
        </li>
        <li class="nav-item" @click="changeLanguage('ar')" :class="{ active: selectedLanguage === 'ar' }">
          <a class="nav-link" href="#">العربية</a>
        </li>
      </ul>
    </nav>

    <!-- Ensure form.title and form.fields exist before rendering -->
    <h3 v-if="!loading && form && form.title">{{ form.title[selectedLanguage] || 'Untitled Form' }}</h3>
    <p v-if="loading">Loading form...</p>

    <!-- Main Form -->
    <form v-if="!loading && form && form.fields" @submit.prevent="submitForm">
      <div class="form-group mb-3" v-for="(field, index) in form.fields" :key="index">
        <label>{{ field.label[selectedLanguage] || 'Untitled Field' }}</label>

        <!-- Text Input Field -->
        <input
          v-if="field.type === 'text'"
          v-model="field.value"
          type="text"
          class="form-control"
          :placeholder="field.placeholder ? field.placeholder[selectedLanguage] : ''"
          :class="{ 'is-invalid': field.required && !field.value && showErrors }"
        />

        <!-- Dropdown Field -->
        <select
          v-if="field.type === 'dropdown'"
          class="form-select"
          v-model="field.value"
          :class="{ 'is-invalid': field.required && !field.value && showErrors }"
        >
          <option v-for="option in (field.options[selectedLanguage] || [])" :key="option">{{ option }}</option>
        </select>

        <!-- Checkbox Field -->
        <div v-if="field.type === 'checkbox'">
          <div v-for="option in (field.options[selectedLanguage] || [])" :key="option" class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              :value="option"
              v-model="field.selectedOptions"
            />
            <label class="form-check-label">{{ option }}</label>
          </div>
        </div>

        <!-- Star Rating Field -->
        <div v-if="field.type === 'star'">
          <div class="star-rating">
            <span v-for="n in field.maxStars" :key="n" class="star" @click="setStarRating(index, n)">
              {{ n <= field.value ? '⭐' : '☆' }}
            </span>
          </div>
        </div>

        <!-- Emoji Radio Field -->
        <div v-if="field.type === 'emoji'">
          <div class="form-check form-check-inline">
            <input type="radio" v-model="field.value" value="😀" class="form-check-input" id="emoji1">
            <label class="form-check-label" for="emoji1">😀</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" v-model="field.value" value="😐" class="form-check-input" id="emoji2">
            <label class="form-check-label" for="emoji2">😐</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" v-model="field.value" value="😔" class="form-check-input" id="emoji3">
            <label class="form-check-label" for="emoji3">😔</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" v-model="field.value" value="😠" class="form-check-input" id="emoji4">
            <label class="form-check-label" for="emoji4">😠</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" v-model="field.value" value="😡" class="form-check-input" id="emoji5">
            <label class="form-check-label" for="emoji5">😡</label>
          </div>
        </div>

        <!-- Display Validation Errors -->
        <div v-if="field.required && !field.value && showErrors" class="text-danger">
          This field is required.
        </div>
      </div>
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>

    <p v-if="message" class="mt-4">{{ message }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: null, // Initialize as null to prevent errors
      selectedLanguage: 'en',
      isRtl: false,
      loading: true,
      message: '',
      showErrors: false
    };
  },
  methods: {
    // Load the form data dynamically
    loadForm() {
      const formId = this.$route.params.formId;
      fetch(`https://dasroor.com/api/get_form.php?id=${formId}`)
        .then(response => response.json())
        .then(data => {
          this.form = data;

          // Initialize form title and labels in each field
          this.form.title = {
            en: this.form.title?.en || '',
            fa: this.form.title?.fa || '',
            ar: this.form.title?.ar || ''
          };
          
          this.form.fields.forEach(field => {
            field.label = {
              en: field.label?.en || '',
              fa: field.label?.fa || '',
              ar: field.label?.ar || ''
            };
            if (field.type === 'checkbox') {
              field.selectedOptions = [];
            }
          });

          this.loading = false;
        })
        .catch(error => {
          console.error('Error loading form:', error);
          this.message = 'There was a problem loading the form.';
          this.loading = false;
        });
    },
    // Set star rating
    setStarRating(index, rating) {
      this.form.fields[index].value = rating;
    },
    // Validate form fields before submission
    validateForm() {
      return this.form.fields.every(field => {
        if (field.required && !field.value) return false;
        if (field.type === 'checkbox' && field.required && field.selectedOptions.length === 0) return false;
        return true;
      });
    },
    // Change language
    changeLanguage(language) {
      this.selectedLanguage = language;
      this.isRtl = language === 'fa' || language === 'ar';
    },
    // Submit the form
    submitForm() {
      this.showErrors = true;

      if (!this.validateForm()) {
        this.message = 'Please fill all required fields.';
        return;
      }

      const responses = this.form.fields.map(field => {
        if (field.type === 'checkbox') {
          return { label: field.label[this.selectedLanguage], value: field.selectedOptions };
        } else {
          return { label: field.label[this.selectedLanguage], value: field.value };
        }
      });

      fetch('https://dasroor.com/api/submit_form.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ responses, formId: this.$route.params.formId })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            this.message = 'Form submitted successfully!';
          } else {
            this.message = `Error submitting form: ${data.message}`;
          }
        })
        .catch(error => {
          console.error('Error submitting form:', error);
          this.message = 'There was an error submitting the form.';
        });
    }
  },
  mounted() {
    this.loadForm();
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: auto;
}
button {
  margin-top: 10px;
}
.star-rating {
  font-size: 2rem;
}
.star {
  cursor: pointer;
  color: gold;
}
.is-invalid {
  border-color: red;
}
.text-danger {
  color: red;
}
</style>
