<template>
  <div class="container mt-5">
    <h3 v-if="form && form.title">Responses for {{ form.title }}</h3>

    <!-- Display a message if there are no responses -->
    <p v-if="!loading && responses.length === 0">No responses found for this form.</p>

    <!-- Display responses if they exist -->
    <div v-if="!loading && responses.length > 0">
      <div v-for="response in responses" :key="response.id" class="response-item">
        <h5>Response {{ response.id }} (Submitted at {{ response.submitted_at }})</h5>
        <div v-for="(answer, index) in response.responses" :key="index">
          <strong>{{ answer.label }}:</strong> {{ formatValue(answer.value) }}
        </div>
      </div>
    </div>

    <p v-if="loading">Loading responses...</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: null, // Initialize form as null
      responses: [], // Initialize responses as an empty array
      loading: true // Start with loading set to true
    };
  },
  methods: {
    // Function to handle formatting the response value, including handling arrays and emojis
    formatValue(value) {
      if (Array.isArray(value)) {
        return value.join(', '); // If it's an array, join the values with commas
      }
      if (typeof value === 'string' && value.startsWith('ud83')) {
        // Handle emoji Unicode value
        return this.convertUnicodeToEmoji(value);
      }
      return value;
    },
    // Function to convert emoji Unicode to actual emoji character
    convertUnicodeToEmoji(unicodeStr) {
      return unicodeStr.replace(/\\u/g, "%u").replace(/%u([0-9a-f]{4})/gi, function(match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    },
    loadFormResponses() {
      const formId = this.$route.params.formId;
      fetch(`https://dasroor.com/api/get_responses.php?form_id=${formId}`)
        .then(response => response.json())
        .then(data => {
          if (data) {
            this.form = data.form || {}; // Ensure form is always an object
            this.responses = data; // Assume the data is directly the responses
            this.loading = false;
          }
        })
        .catch(error => {
          console.error('Error fetching responses:', error);
          this.loading = false;
        });
    }
  },
  mounted() {
    this.loadFormResponses(); // Load form responses when component mounts
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: auto;
}
.response-item {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
}
</style>
