<template>
  <div class="container mt-5">
    <h2>Admin Login</h2>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" v-model="email" class="form-control" id="email" required />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" v-model="password" class="form-control" id="password" required />
      </div>
      <button type="submit" class="btn btn-primary mt-3">Login</button>
    </form>

    <p v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: "AdminLogin", // Change the name to something multi-word
  data() {
    return {
      email: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    login() {
      fetch('https://dasroor.com/api/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: this.email, password: this.password })
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          localStorage.setItem('adminLoggedIn', true);
          this.$router.push('/create');
        } else {
          this.errorMessage = data.message;
        }
      })
      .catch(error => {
        console.error('Error during login:', error);
        this.errorMessage = 'An error occurred during login.';
      });
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 400px;
}
</style>
