<template>
  <div :dir="isRtl ? 'rtl' : 'ltr'" class="container mt-5">
    <h2 v-if="!loading">{{ isEditing ? 'Edit Form' : 'Create a New Form' }}</h2>
    <p v-if="loading">Loading form...</p>

    <!-- Form to Add/Edit Fields in Three Languages -->
    <div v-if="!loading" class="form-group">
      <label for="questionType">Select Question Type:</label>
      <select v-model="newField.type" class="form-select" id="questionType">
        <option value="text">Text Input</option>
        <option value="dropdown">Dropdown</option>
        <option value="checkbox">Checkbox</option>
        <option value="star">Star Rating</option>
        <option value="emoji">Emoji Rating</option>
      </select>
    </div>

    <div v-if="!loading" class="form-group">
      <label for="questionLabel">Question Label (English)</label>
      <input
        type="text"
        v-model="newField.label_en"
        class="form-control"
        placeholder="Enter the question label in English"
      />

      <label for="questionLabel">برچسب سوال (فارسی)</label>
      <input
        type="text"
        v-model="newField.label_fa"
        class="form-control"
        placeholder="برچسب سوال را به فارسی وارد کنید"
      />

      <label for="questionLabel">تسمية السؤال (العربية)</label>
      <input
        type="text"
        v-model="newField.label_ar"
        class="form-control"
        placeholder="أدخل تسمية السؤال بالعربية"
      />
    </div>

    <!-- Add Dropdown/Checkbox Options for Each Language -->
    <div v-if="newField.type === 'dropdown' || newField.type === 'checkbox'">
      <label>{{ newField.type === 'dropdown' ? 'Dropdown Options' : 'Checkbox Options' }} (English)</label>
      <div v-for="(option, index) in newField.options_en" :key="index" class="form-group">
        <input
          v-model="newField.options_en[index]"
          type="text"
          class="form-control"
          :placeholder="`Option ${index + 1} (English)`"
        />
      </div>

      <label>{{ newField.type === 'dropdown' ? 'گزینه‌ها' : 'گزینه‌ها (Checkbox)' }} (فارسی)</label>
      <div v-for="(option, index) in newField.options_fa" :key="index" class="form-group">
        <input
          v-model="newField.options_fa[index]"
          type="text"
          class="form-control"
          :placeholder="`گزینه ${index + 1}`"
        />
      </div>

      <label>{{ newField.type === 'dropdown' ? 'خيارات' : 'خيارات (Checkbox)' }} (العربية)</label>
      <div v-for="(option, index) in newField.options_ar" :key="index" class="form-group">
        <input
          v-model="newField.options_ar[index]"
          type="text"
          class="form-control"
          :placeholder="`الخيار ${index + 1}`"
        />
      </div>

      <button @click="addOption" class="btn btn-secondary mt-2">Add Option</button>
    </div>

    <!-- Star Rating Configuration -->
    <div v-if="newField.type === 'star'">
      <label for="starRating">Max Stars (Default is 5):</label>
      <input
        type="number"
        v-model="newField.maxStars"
        min="1"
        max="10"
        class="form-control"
        placeholder="Set the maximum number of stars"
      />
    </div>

    <!-- Button to Add/Edit Question -->
    <button @click="addField" class="btn btn-primary mt-4">{{ isEditingQuestion ? 'Save Question' : 'Add Question' }}</button>
    <button v-if="isEditingQuestion" @click="cancelEdit" class="btn btn-secondary mt-4">Cancel Edit</button>

    <!-- Display Added Fields -->
    <div v-if="formFields.length > 0" class="mt-5">
      <h3>Preview Form</h3>
      <div v-for="(field, index) in formFields" :key="index" class="mb-2">
        <p><strong>{{ field.label_en }} ({{ field.type }})</strong></p>
        <p><strong>{{ field.label_fa }} (فارسی)</strong></p>
        <p><strong>{{ field.label_ar }} (العربية)</strong></p>

        <!-- Preview for Dropdown or Checkbox Options -->
        <div v-if="field.type === 'dropdown' || field.type === 'checkbox'">
          <h5>Options (English)</h5>
          <ul>
            <li v-for="option in field.options_en" :key="option">{{ option }}</li>
          </ul>
          <h5>گزینه‌ها (فارسی)</h5>
          <ul>
            <li v-for="option in field.options_fa" :key="option">{{ option }}</li>
          </ul>
          <h5>خيارات (العربية)</h5>
          <ul>
            <li v-for="option in field.options_ar" :key="option">{{ option }}</li>
          </ul>
        </div>

        <!-- Preview for Star Rating -->
        <div v-if="field.type === 'star'">
          <span v-for="n in field.maxStars" :key="n">⭐</span>
        </div>

        <!-- Preview for Emoji Radio -->
        <div v-if="field.type === 'emoji'">
          <p>😀 😐 😔 😠 😡</p>
        </div>

        <!-- Edit or Remove Question -->
        <button @click="editField(index)" class="btn btn-warning btn-sm">Edit Question</button>
        <button @click="removeField(index)" class="btn btn-danger btn-sm">Remove Question</button>
      </div>
    </div>

    <!-- Save Form Button -->
    <button @click="saveForm" class="btn btn-success mt-4">Save {{ isEditing ? 'Changes' : 'Form' }}</button>

    <!-- Display Shareable Link -->
    <div v-if="shareLink" class="mt-4">
      <p>Form saved! Share this link: <a :href="shareLink">{{ shareLink }}</a></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formFields: [], // Stores all added fields
      newField: {
        type: 'text', // Default type is text input
        label_en: '',
        label_fa: '',
        label_ar: '',
        options_en: [], // Options only used if the type is dropdown or checkbox
        options_fa: [],
        options_ar: [],
        maxStars: 5 // Default for star rating
      },
      isRtl: false,
      shareLink: '', // Holds the generated shareable link
      isEditing: false, // Flag to check if editing an existing form
      isEditingQuestion: false, // Flag to check if editing a question
      editingIndex: -1, // Holds the index of the question being edited
      loading: true, // Loading state while fetching data
      showError: false // Flag to show validation errors
    };
  },
  methods: {
    addField() {
      this.showError = false;

      // Validate fields
      if (this.newField.label_en === '' || this.newField.label_fa === '' || this.newField.label_ar === '') {
        this.showError = true;
        return;
      }

      if ((this.newField.type === 'dropdown' || this.newField.type === 'checkbox') &&
          (this.newField.options_en.length < 2 || this.newField.options_fa.length < 2 || this.newField.options_ar.length < 2)) {
        alert('Dropdowns and checkboxes must have at least 2 options in each language.');
        return;
      }

      if (this.isEditingQuestion) {
        // Save edited question
        this.formFields.splice(this.editingIndex, 1, { ...this.newField });
        this.isEditingQuestion = false;
      } else {
        // Add new question
        this.formFields.push({ ...this.newField });
      }

      // Reset newField for the next addition
      this.resetNewField();
    },
    resetNewField() {
      this.newField = {
        type: 'text',
        label_en: '',
        label_fa: '',
        label_ar: '',
        options_en: [],
        options_fa: [],
        options_ar: [],
        maxStars: 5
      };
    },
    addOption() {
      this.newField.options_en.push('');
      this.newField.options_fa.push('');
      this.newField.options_ar.push('');
    },
    editField(index) {
      this.newField = { ...this.formFields[index] };
      this.isEditingQuestion = true;
      this.editingIndex = index;
    },
    removeField(index) {
      this.formFields.splice(index, 1);
    },
  saveForm() {
    const apiUrl = this.isEditing
      ? `https://dasroor.com/api/update_form.php?id=${this.$route.params.formId}`
      : 'https://dasroor.com/api/save_form.php';

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fields: this.formFields }), // Send form fields as JSON
    })
    .then(response => response.json())
    .then(data => {
      console.log('API response:', data); // Log the API response
      if (data.success) {
        this.shareLink = `https://dasroor.com/submit/${data.form_id}`;
        alert('Form saved successfully! Share the link: ' + this.shareLink);
      } else {
        alert('Error saving form: ' + data.message); // Show detailed error message
      }
    })
    .catch(error => {
      console.error('Error saving form:', error); // Log network or other errors
      alert('There was an error saving the form.');
    });
}
  },
  mounted() {
    if (this.$route.params.formId) {
      this.isEditing = true;
      fetch(`https://dasroor.com/api/get_form.php?id=${this.$route.params.formId}`)
        .then(response => response.json())
        .then(data => {
          this.formFields = data.fields;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error loading form:', error);
          alert('There was an error loading the form data.');
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  },
  watch: {
    selectedLanguage(language) {
      this.isRtl = language === 'ar' || language === 'fa';
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
}
button {
  margin-top: 10px;
}
.is-invalid {
  border-color: #e3342f;
}
.text-danger {
  color: #e3342f;
}
</style>
