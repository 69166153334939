import { createI18n } from 'vue-i18n';

// Translation messages
const messages = {
  en: {
    createForm: 'Create a New Form',
    editForm: 'Edit Form',
    questionType: 'Select Question Type',
    questionLabel: 'Question Label',
    addOption: 'Add Option',
    saveForm: 'Save Form',
    language: 'Language',
    // Add more translations here...
  },
  fr: {
    createForm: 'Créer un nouveau formulaire',
    editForm: 'Modifier le formulaire',
    questionType: 'Sélectionnez le type de question',
    questionLabel: 'Intitulé de la question',
    addOption: 'Ajouter une option',
    saveForm: 'Enregistrer le formulaire',
    language: 'Langue',
    // Add more translations here...
  },
  // Add more languages here...
};

const i18n = createI18n({
  locale: 'en', // default language
  fallbackLocale: 'en',
  messages,
});

export default i18n;
