<template>
  <div :dir="isRtl ? 'rtl' : 'ltr'">
    <!-- Navbar -->
    <nav v-if="!isSubmitFormPage" class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Vana Company</a>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav ms-auto">
            <!-- Show these links only if admin is logged in -->
            <li class="nav-item" v-if="adminLoggedIn">
              <router-link to="/create" class="nav-link">Create Form</router-link>
            </li>
            <li class="nav-item" v-if="adminLoggedIn">
              <router-link to="/responses" class="nav-link">View Responses</router-link>
            </li>
            <li class="nav-item" v-if="adminLoggedIn">
              <router-link to="/edit" class="nav-link">Edit Form</router-link>
            </li>
            <li class="nav-item" v-if="adminLoggedIn">
              <a class="nav-link" @click="logout">Logout</a>
            </li>
            <li class="nav-item" v-if="!adminLoggedIn">
              <router-link to="/login" class="nav-link">Admin Login</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Router View for showing page content -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
 isRtl() {
      return this.$i18n.locale === 'ar' || this.$i18n.locale === 'fa';
    },
  computed: {
    adminLoggedIn() {
      return localStorage.getItem('adminLoggedIn') === 'true';
    },
    isSubmitFormPage() {
      return this.$route.path.startsWith('/submit');
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('adminLoggedIn');
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.navbar {
  margin-bottom: 20px;
}


/* Base styling */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f7f7f7;
  color: #333;
}

body[dir="rtl"] {
  text-align: right;
}

body[dir="ltr"] {
  text-align: left;
}

/* Navbar Styling */
.navbar {
  background-color: #4a4a4a; /* Dark wood-like color */
  color: #fff;
  padding: 1rem;
}

.navbar-brand {
  color: #000;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-link {
  color: #000;
  margin-right: 1rem;
}

.nav-link:hover {
  color: #f1c40f; /* Gold hover effect */
}

/* Container Styling */
.container {
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
  margin-top: 2rem;
}

/* Buttons */
.btn-primary {
  background-color: #f1c40f;
  border-color: #f1c40f;
  color: #333;
}

.btn-primary:hover {
  background-color: #e1b70d;
  border-color: #e1b70d;
}

/* Form Elements */
.form-control {
  border-radius: 4px;
  padding: 0.75rem;
  border: 1px solid #ccc;
}

.form-control:focus {
  border-color: #f1c40f;
  box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.25);
}

/* SubmitForm Page */
.submit-page {
  background-color: #f7f7f7;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-form-container {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

</style>
